import { instance } from "../../utils/APi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const setCheckout = createAsyncThunk(
  "check/setCheckout",
  async ({ values, token }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.post(`api/orderRequest`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const GetOrders = createAsyncThunk(
  "check/GetOrders",
  async (token, thunkAPI) => {
    const { rejectWithValue,  signal  } = thunkAPI;
    try {
      const response = await instance.get(`api/mypurchases`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },   { signal });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const cancelOrders = createAsyncThunk(
  "check/cancelOrders",
  async ({ id, token }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.get(
        `api/cancelOrder/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const applyCoupon = createAsyncThunk(
  "check/applyCoupon",
  async ({ token, code }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.get(
        `api/discountcode?discount_code=${code}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
// export const CheckMob = createAsyncThunk(
//   "check/applyCoupon",
//   async (_, thunkAPI) => {
//     const { rejectWithValue } = thunkAPI;
//     try {
//       const response = await instance.get(
//         `api/states`,
//       );
//       return response.data.data;
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       return rejectWithValue(error.message);
//     }
//   }
// );
