import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { failed } from "./toast/Toast";
import { useTranslation } from "react-i18next";
import { removeAllFromCart } from "../store/cart/cartSlice";
import { useDispatch } from "react-redux";

const PaymentResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get("success");
    const amount = searchParams.get("amount_cents");
    const message = searchParams.get("data.message");
    
    // Check if payment was successful
    if (success === "true") {
      // Display a success message
      dispatch(removeAllFromCart());
      console.log(`Payment Successful! Amount: ${amount}, Message: ${message}`);

      // Redirect to the orders page
      navigate('/profile', { state: { selectedTab: 'My orders' } });
    } else {
      // Handle failure case if needed
      navigate('/cart');
      failed(t("please try again"));

    }
  }, [location.search, navigate,t]);

  return <div>Processing your payment...</div>;
};

export default PaymentResult;
