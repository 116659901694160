import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { openQuickModal, openCartModal } from "../store/modal/ModalSlicer";
import { addTofav } from "../store/cart/cartAction";
import { baseUrl } from "../utils/APi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { failed, sucess } from "./toast/Toast";
import { addingCart } from "../store/cart/cartSlice";

const Card = ({ item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cartSlice);
  const { token } = useSelector((state) => state.authSlice);
  const [selectedImage, setSelectedImage] = useState(
    item?.image || item?.product_photo
  );

  const totalStars = item?.reviews?.reduce(
    (acc, review) => acc + review?.stars,
    0
  );
  useEffect(() => {
    setSelectedImage(item?.image || item?.product_photo);
  }, [item]);

  const averageRating =
    item?.reviews?.length > 0 ? totalStars / item?.reviews?.length : 0;

  const addFav = () => {
    if (token) {
      dispatch(addTofav({ id: item?.id, token }));
      dispatch(openCartModal(item));
    } else {
      failed(t("first you must have an account"));
    }
  };
  const addTOCart = (item) => {
    const cartItem = {
      productId: item?.id,
      productName: item?.name,
      productPhoto: item?.image,
      quantity: 1,
      price:
        item?.discount_price && item?.discount_price !== 0
          ? item?.discount_price
          : item?.price,
    };
    const itemExists = cart.some(
      (cartItem) => cartItem.productId === item.id
    );
  
    if (itemExists) {
      failed(t("The item is already in the cart"));
    } else {
      dispatch(addingCart({ cartItem }));
      sucess(t("added successfully"));
    }
  };
  return (
    item && (
    <div className="tt-product thumbprod-center">
          <div className="tt-image-box">
            <i
              onClick={addFav}
              className="fa-regular fa-heart d-none d-lg-inline"
            ></i>
            <i
              onClick={() => dispatch(openQuickModal(item))}
              className="fa-solid fa-cart-plus d-none d-lg-inline"
            ></i>

            <span className="tt-img">
              <Link to={`/products/${item?.id}`}>
                <img src={`${baseUrl}${selectedImage}`} alt={item?.name} />
              </Link>
            </span>

            <span className="tt-img-roll-over">
              {item?.images && item?.images?.length > 0 ? (
                <Link to={`/products/${item?.id}`} key={item?.id}>
                  <LazyLoadImage
                    src={`${baseUrl}${item?.images[0]?.image}`}
                    alt={`${item?.images[0]?.image}`}
                  />
                </Link>
              ) : (
                ""
              )}
            </span> 

            <span className="tt-label-location">
              <span className="tt-label-sale">
                {item?.has_discount ? item?.discount_price : "Sale 0%"}
              </span>
            </span>
            <button className="btn btn-lg cartBtn" onClick={()=>addTOCart(item)}>{t("QUICK ADD")} 
            <i className="fa-solid fa-cart-arrow-down mx-2" ></i></button>
          </div>
          <div className="tt-description">
            <div className="tt-row">
              <ul className="tt-add-info">
                <li>{item?.category?.name}</li>
              </ul>
              <div className="tt-rating">
                {averageRating === 0 ? (
                  <>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                  </>
                ) : (
                  Array.from({ length: Math.ceil(averageRating) }).map(
                    (_, index) => (
                      <i key={index} className="fa-solid fa-star icon-star"></i>
                    )
                  )
                )}
              </div>
              <h2 className="tt-title">
                <Link to={`/products/${item?.id}`}>{item?.name}</Link>
              </h2>
            </div>
            <div className="tt-price">
              <span className="old-prices">{item?.currency}</span>
              <span className="new-price mx-1">{item?.price}</span>
            </div>
            <div className="tt-option-block d-none d-md-block d-none d-md-block">
              <ul className="tt-options-swatch js-change-img">
                {item?.images?.map((item, index) => (
                  <li
                    key={index}
                    className={
                      selectedImage === item?.image ? "active-img" : ""
                    }
                  >
                    <span
                      className="options-color-img"
                      onClick={() => setSelectedImage(item?.image)}
                    >
                      <LazyLoadImage
                        src={`${baseUrl}${item?.image}`}
                        alt={`${baseUrl}${item?.image}`}
                      />
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="tt-option-block d-none d-md-block">
              <ul className="tt-options-swatch js-change-img">
                {item?.attributes?.map((item, index) => {
                  if (
                    item?.name.includes("المقاس") ||
                    item?.name.toLowerCase().includes("measuring")
                  ) {
                    return (
                      <li key={index}>
                        <a
                          href="#1"
                          className="options-size"
                          data-tooltip="small"
                          data-tposition="top"
                          onClick={(e) => e.preventDefault()}
                        >
                          {item?.value}
                        </a>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            </div>
            <div className="tt-product-inside-hover">
              <div className="tt-row-btn">
                <button
                  className="tt-btn-addtocart thumbprod-button-bg"
                  onClick={() => dispatch(openQuickModal(item))}
                >
                  {t("add to cart")}
                </button>
              </div>
              <div className="tt-row-btn">
                <i onClick={addFav} className="fa-regular fa-heart"></i>
              </div>
            </div>
          </div>
    </div>
  )
  );
};

export default React.memo(Card);
