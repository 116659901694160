import { Fragment, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { GetOrders, cancelOrders } from "../../store/checkout/checkAction";
import { sucess } from "../../component/toast/Toast";
import { Link } from "react-router-dom";

const Orders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.checkslice);
  const { token } = useSelector((state) => state.authSlice);
console.log(orders)
  useEffect(() => {
    dispatch(GetOrders(token));
  }, [dispatch, token]);

  const handleCanecl = async (id) => {
    await dispatch(cancelOrders({ id: id, token }));
    sucess(t("Order successfully canceled."));
    dispatch(GetOrders(token));
  };
 
  return (
    
      orders && orders?.length > 0  ? 
      ( orders?.map((item, idx) => (
        <div className="tt-shopping-layout orders" key={idx}>
          <h2 className="tt-title">{t("ORDER")} #{item?.id}</h2>
          <div className="tt-data">{item?.date}</div>
          <div className="tt-wrapper">
            <div className="tt-table-responsive">
              <table className="tt-table-shop-01">
                <thead>
                  <tr>
                    <th>{t("PRODUCTS")}</th>
                    <th>{t("price")}</th>
                    <th>{t("quantity")}</th>
                    <th>{t("TOTAL")}</th>
                  </tr>
                </thead>
                <tbody>
                  {item?.products?.map((product, index) => (
                    <Fragment key={index}>
                      <tr>
                        <td>{product?.name}</td>
                        <td>{product?.price}</td>
                        <td>{product?.quantity}</td>
                        <td>{product?.price * product?.quantity}</td>
                      </tr>
                    </Fragment>
                  ))}
                  <tr>
                    <td colSpan="3">
                      <strong>{t("SubTotal")}</strong>
                    </td>
                    <td>{item?.total - item?.shipping}</td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <strong>{t("Shipping Cost")}</strong>
                    </td>
                    <td>{item?.shipping}</td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <strong>{t("TOTAL")}</strong>
                    </td>
                    <td>
                      <strong>
                        {item?.total} {item?.order_currency}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="container my-4">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="container p-1 align-items-center">
                  <div className="d-flex justify-content-around">
                    {item?.allStatus?.map((state, idx) => (
                      <Fragment key={idx}>
                        <button
                          className={`p-2 rounded-circle ${
                            idx === item?.allStatus?.length - 1
                              ? state?.title === "تم الالغاء"
                                ? "bg-reject text-white"
                                : "bg-done text-white"
                              : "bg-secondary text-white"
                          }`}
                          title={state?.title}
                        >
                          <i className="fa-solid fa-check"></i>
                        </button>
                        <span
                          className={`bg-${
                            idx === item?.allStatus?.length - 1
                              ? state?.title === "تم الالغاء"
                                ? "reject"
                                : "done"
                              : "secondary"
                          } text-center w-100 rounded mt-auto mb-auto text-white`}
                        >
                          {state?.title}
                        </span>
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tt-wrapper">
            <div className="tt-shop-info">
              <div className="tt-item mb-4">
                <strong>
                  <span> {t("status")} : {item?.status}</span>
                </strong>
                <h6 className="tt-title">{t("points")}: {item?.points}</h6>
              </div>
            </div>
          </div>
          {item?.status === "جارى التجهيز" || item?.status === "جديد" ? (
            <button
              className="btn main-btn my-4"
              onClick={() => handleCanecl(item?.id)}
            >
              {t("Cancel Order")}
            </button>
          ) : (
            ""
          )}
        </div>
      ))) : (
        <div className="tt-empty-cart">
                    <span className="tt-icon icon-f-39"></span>
                    <h1 className="tt-title">{t("You havn't any orders yet")}</h1>
                    <p>{t("let's make your first one.")}</p>
                    <Link to={"/"} className="btn">
                      {t("CONTINUE SHOPPING")}
                    </Link>
                  </div>
                )
  );
};

export default Orders;
