import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../utils/APi";
import { getSlides } from "../../store/slider/SliderAction";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/effect-fade';
import "swiper/css/navigation";
import { Pagination, Autoplay ,EffectFade} from "swiper/modules";
import Loader from "../../component/Loader";

const HomeSlider = () => {
  const dispatch = useDispatch();
  const { slider, loading } = useSelector((state) => state.slider);


  useEffect(() => {
    if (!slider.length) {
      const promise = dispatch(getSlides());
      return () => {
        promise.abort();
      };
    }
  }, [dispatch, slider.length]);

  if (loading !== "idle") return <Loader />;

  return (
    loading === "idle" && (
      <section className="w-100 dir mb-4 mb-md-0">
        <Swiper
          modules={[Pagination , Autoplay ,EffectFade]}
          pagination={{
            clickable: true,
          }}
          effect={'fade'}
          loop={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          className="mySwiper"
        >
          {slider?.map((item, idx) => (
            <SwiperSlide
              className="slider-revolution revolution-default"
              key={item?.id}
            >
              <div className="tp-banner-container">
                <img to={"/totaloffers"} src={`${baseUrl}${item?.image}`} alt={`slide${item?.id}}`} />
              
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    )
  );
};

export default HomeSlider;
