import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getContactInfo, getPayment } from "../../store/contact/contactAction";
import { useEffect } from "react";
import { baseUrl } from "../../utils/APi";

const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.Productslice);
  const lan = useSelector((state) => state.LanSlicer.language);
  const { info, SocialMedia ,payments ,loading} = useSelector((state) => state.contactSlice);
  const { user,token } = useSelector((state) => state.authSlice);

  useEffect(() => {
    if(token){
      dispatch(getPayment(token));
    } 
    const promise = dispatch(getContactInfo(lan));
    return () => {
      promise.abort();
    };
  }, [ lan , dispatch , token ]);
 
  return (
    <footer id="tt-footer">
      <div className="tt-footer-col tt-color-scheme-01 py-0 py-md-5">
        <div className="container py-0 py-md-5 pt-4 pt-md-0">
          <div className="row">
            <div className="col-md-6 col-lg-2 col-xl-3">
              <div className="pb-2">
                <h4 className="tt-collapse-title">{t("categories")}</h4>
                <div className="tt-collapse-content">
                  <ul className="tt-list">
                    <li>
                      <Link to={"/"}>{t("home")}</Link>
                    </li>
                    <li>
                      <Link to={"/totaloffers"}>{t("offers")}</Link>
                    </li>
                    {category?.slice(0, 3).map((item, idx) => (
                      <li key={idx}>
                        <Link to={`/Category/${item?.id}`}>{item?.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 col-xl-3">
              <div className="pb-2">
                <h4 className="tt-collapse-title">{t("my account")}</h4>
                <div className="tt-collapse-content">
                  <ul className="tt-list">
                    <li>
                      <Link to={"/about"}>{t("about us")}</Link>
                    </li>
                    <li>
                      <Link to={"/policy"}>{t("policy")}</Link>
                    </li>
                    <li>
                      <Link to={"/cart"}>{t("cart")}</Link>
                    </li>
                    <li>
                      <Link to={"/wishlist"}>{t("wishlist")}</Link>
                    </li>
                    {!user ? (
                      <>
                        <li>
                          <Link to={"/login"}>{t("login")}</Link>
                        </li>
                        <li>
                          <Link to={"/register"}>{t("register")}</Link>
                        </li>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="pb-2">
                <h4 className="tt-collapse-title">{t("contacts")}</h4>
                <div className="tt-collapse-content">
                  <address>
                    <p>
                      <span>{t("address")}:</span> {t("choose your")}
                      <Link to={"/branch"}>{t("branch")}</Link>
                    </p>
                    <p>
                      <span>{t("phone")}:</span>
                      {info?.map((item) => {
                        if (item?.name === "رقم الهاتف") {
                          return <span key={item?.id}>{item?.value}</span>;
                        }
                        return null;
                      })}
                    </p>

                    <p>
                      <span>{t("e-mail")}:</span>
                      <a href="mailto:info@mydomain.com">
                          {info?.map((item) => {
                          if (item?.name === "البريد الالكترونى") {
                            return <span key={item?.id}>{item?.value}</span>;
                          }
                          return null;
                        })}
                      </a>
                    </p>
                  </address>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="tt-newsletter">
                <div className="pb-2">
                  <h4 className="tt-collapse-title">
                    {t("newsletter signup")}
                  </h4>
                  <div className="tt-collapse-content">
                    <p>
                      {t(
                        "sign up for our e-mail and be the first who know our special offers! Further more"
                      )}
                      {!user ? (  <Link className="mx-3" to={"/register"}>
                        {t("Sign up Now")}
                      </Link>) : ""}
                    </p>
                  </div>
                </div>
              </div>
              <ul className="tt-social-icon d-none d-md-flex">
                <li>
                  {SocialMedia?.data?.map((item) => {
                    if (item?.name === "الفيس بوك") {
                      return (
                        <a
                          key={item?.id}
                          aria-label="facebook"
                          target="_blank"
                          rel="noreferrer"
                          href={item?.link}
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      );
                    }
                    return null;
                  })}
                </li>
                <li>
                  {SocialMedia?.data?.map((item) => {
                    if (item?.name === "انستجرام") {
                      return (
                        <a
                          key={item?.id}
                          aria-label="instagram"
                          target="_blank"
                          rel="noreferrer"
                          href={item?.link}
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      );
                    }
                    return null;
                  })}
                </li>
                <li>
                  {SocialMedia?.data?.map((item) => {
                    if (item?.name === "الفيس بوك") {
                      return (
                        <a
                          key={item?.id}
                          aria-label="facebook"
                          target="_blank"
                          rel="noreferrer"
                          href={item?.link}
                        >
                          <i className="fa-brands fa-facebook-messenger"></i>
                        </a>
                      );
                    }
                    return null;
                  })}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="tt-footer-custom">
        <div className="container">
          <div className="tt-row">
            <div className="tt-col-left">
             
              <div className="tt-col-item">
                <div className="tt-box-copyright">
                  &copy;{" "}
                  <Link to={"https://pioneers-solutions.com/"} target="_blank">
                    Pioneers Solutions{" "}
                  </Link>{" "}
                  2024.{t("All Rights Reserved")}
                </div>
              </div>
            </div>
            <div className="tt-col-right">
              <div className="tt-col-item d-flex justify-items-center align-items-center">
              {payments?.map((item, idx) => (
                     <li key={idx} className="mx-1">
                         <img src={`${baseUrl}${item?.image}`} className="img-fluid footer-image" alt="payment" style={{width:"100px",height:"50px"}} />
                         </li>
                    ))}
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
