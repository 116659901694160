import React, { Fragment} from "react";
import Banner from "./Banner";
import BannerBottom from "./BannerBottom";
import Arrivals from "./Arrivals";
import Featured from "./Featured";
import TopRated from "./TopRated";
import Offer from "./Offer";
import Gallery from "./Gallery";


const Home = () => {
  
  return (
    <Fragment>
        <Banner /> 
        <BannerBottom />
        <Arrivals /> 
        <Featured /> 
        <Offer /> 
        <TopRated /> 
        <Gallery />  
    </Fragment>
  );
};

export default Home;
