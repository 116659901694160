import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeQuickModal } from "../store/modal/ModalSlicer";
import { useTranslation } from "react-i18next";
import { getProducts, getsingleProduct } from "../store/products/productAction";
import { baseUrl } from "../utils/APi";
import { addingCart } from "../store/cart/cartSlice";
import { failed, sucess } from "./toast/Toast";
import {
  clearproduct,
  clearproductstatus,
} from "../store/products/productSlice";
import { getofferProducts } from "../store/totaloffer/totaloffAction";

const QuickModal = () => {
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [quantity, setQuantity] = useState(1);
  const lan = useSelector((state) => state.LanSlicer.language);
  const { cart } = useSelector((state) => state.cartSlice);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { quickOpen, item } = useSelector((state) => state.ModalSlicer);
  // const { singleProduct } = useSelector((state) => state.Productslice);
  const [selectedImage, setSelectedImage] = useState();

  // useEffect(() => {
  //   if (quickOpen) {
  //     const promise = dispatch(getsingleProduct({ lan, id: productId }));
  //     return () => {
  //       promise.abort();
  //       dispatch(clearproduct());
  //       dispatch(clearproductstatus());
  //     };
  //   }
  // }, [dispatch, lan, productId, quickOpen]);

  const totalStars = item?.reviews?.reduce(
    (acc, review) => acc + review?.stars,
    0
  );
  const averageRating =
  item?.reviews?.length > 0
      ? totalStars / item?.reviews?.length
      : 0;

  const handleImageChange = (newImage) => {
    setSelectedImage(newImage);
  };

  useEffect(() => {
    if (item) {
      setSelectedImage(item?.product_photo ||item?.image);
    }
  }, [item]);

  const addTOCart = () => {
    const cartItem = {
      productId: item?.id,
      productName: item?.name,
      productPhoto: item?.product_photo || item?.image,
      quantity: quantity,
      price:
      item?.discount_price && item?.discount_price !== 0
          ? item?.discount_price
          : item?.price,
      color: selectedColor,
      size: selectedSize,
    };
    const itemExists = cart.some(
      (cartItem) => cartItem.productId === item.id
    );
  
    if (itemExists) {
      failed(t("The item is already in the cart"));
    } else {
      dispatch(addingCart({ cartItem }));
      sucess(t("added successfully"));
    }
  };

  const handlecart = () => {
    addTOCart();
     dispatch(closeQuickModal());
  };
  const handleClose = () => {
    dispatch(closeQuickModal());
  };
 
  return (
      (quickOpen && item) && (
        <div className="quick-modal">
           <div className={`modal-dialog modal-lg ${lan === 'ar' ? 'rtl-style' : 'ltr-style'}`}>
            <div className="modal-content ">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  onClick={() => handleClose()}
                  aria-hidden="true"
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="tt-modal-quickview desctope">
                  <div className="row">
                    <div className="col-12 col-md-5 col-lg-6">
                      <div className="tt-mobile-product-slider arrow-location-center">
                        <div className="img-container">
                          <img
                            src={`${baseUrl}${selectedImage}`}
                            alt={item?.name}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`col-12 col-md-7 col-lg-6 ${lan === 'ar' ? 'text-right' : 'text-left'}`}>
                      <div className="tt-product-single-info">
                        <div className="tt-add-info">
                          <ul>
                            <li>
                              <span>{t("product id :")}</span> {item?.id}
                            </li>
                            <li>
                              <span>{t("Availability :")} </span>
                              {item?.product_quantity} {t("in Stock")} 
                            </li>
                          </ul>
                        </div>
                        <h2 className="tt-title">{item?.name}</h2>
                        <div className="tt-price">
                          {item?.discount_price ? (
                            <span className="old-price">
                              {item?.price}
                              <span className="mx-1">
                                {item?.currency}
                              </span>
                            </span>
                          ) : (
                            <span className="new-price">
                              <span className="old-price"></span>
                            </span>
                          )}
                        </div>

                        <div className="tt-price">
                          <span className="new-price">
                            {item?.discount_price &&
                            item?.discount_price !== 0
                              ? item?.discount_price
                              : item?.price}{" "}
                            <span className="mx-1">
                              {item?.currency}
                            </span>
                          </span>
                        </div>
                        <div className="tt-review">
                          <div className="tt-rating">
                            {averageRating === 0 ? (
                              <>
                                <i className="icon-star-empty"></i>
                                <i className="icon-star-empty"></i>
                                <i className="icon-star-empty"></i>
                                <i className="icon-star-empty"></i>
                                <i className="icon-star-empty"></i>
                              </>
                            ) : (
                              Array.from({
                                length: Math.ceil(averageRating),
                              }).map((_, index) => (
                                <i key={index} className="icon-star"></i>
                              ))
                            )}
                          </div>
                          <a href="#">
                            ({item?.review}{t("customer review")}  )
                          </a>
                        </div>
                        <div
                          className="tt-wrapper"
                          dangerouslySetInnerHTML={{
                            __html: item?.desc,
                          }}
                        />
                        <div className="tt-swatches-container">
                          <div className="tt-wrapper">
                            {item?.attributes?.length > 0 &&
                              item?.attributes?.some(
                                (item) =>
                                  item?.name?.includes("المقاس") ||
                                  item?.name?.toLowerCase().includes("measuring")
                              ) && (
                                <form className="form-default">
                                  <div className="tt-title-options">{t("size")}</div>
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setSelectedSize(e.target.value)
                                      }
                                      value={selectedSize}
                                    >
                                      {item?.attributes?.map(
                                        (item, index) => {
                                          if (
                                            item?.name?.includes("المقاس") ||
                                            item?.name
                                              .toLowerCase()
                                              .includes("measuring")
                                          ) {
                                            return (
                                              <option
                                                key={index}
                                                className="options-size"
                                                value={item?.value}
                                              >
                                                {item?.value}
                                              </option>
                                            );
                                          } else {
                                            return null;
                                          }
                                        }
                                      )}
                                    </select>
                                  </div>
                                </form>
                              )}
                          </div>
                          <div className="tt-wrapper">
                            {item?.attributes?.length > 0 &&
                              item?.attributes?.some(
                                (item) =>
                                  item?.name?.includes("اللون") ||
                                  item?.name?.toLowerCase().includes("color")
                              ) && (
                                <form className="form-default">
                                  <div className="tt-title-options">
                                    {t("color")}
                                  </div>
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        setSelectedColor(e.target.value)
                                      }
                                      value={selectedColor}
                                    >
                                      {item?.attributes?.map(
                                        (item, index) => {
                                          if (
                                            item?.name?.includes("اللون") ||
                                            item?.name
                                              .toLowerCase()
                                              .includes("color")
                                          ) {
                                            return (
                                              <option
                                                key={index}
                                                className="options-size"
                                                value={item?.value}
                                              >
                                                {item?.value}
                                              </option>
                                          
                                            );
                                          } else {
                                            return null;
                                          }
                                        }
                                      )}
                                    </select>
                                  </div>
                                </form>
                              )}
                          </div>
                          <div className="tt-wrapper">
                            <div className="tt-title-options">{t("gallery")} </div>
                            <ul className="tt-options-swatch js-change-img">
                              {item?.images?.map((item, index) => (
                                <li
                                  key={index}
                                  className={
                                    selectedImage === item?.image
                                      ? "active-img"
                                      : ""
                                  }
                                >
                                  <span
                                    className="options-color-img"
                                    onClick={() =>
                                      handleImageChange(item?.image)
                                    }
                                  >
                                    <img
                                      src={`${baseUrl}${item?.image}`}
                                      alt={`${baseUrl}${item?.image}`}
                                    />
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="tt-wrapper">
                          <div className="tt-row-custom-01">
                            <div className="col-item">
                              <div className="tt-input-counter style-01">
                                <i
                                  className="fa-solid fa-minus minus-btn"
                                  onClick={() =>
                                    setQuantity((prevQuantity) =>
                                      Math.max(prevQuantity - 1, 1)
                                    )
                                  }
                                ></i>

                                <input
                                  type="text"
                                  value={quantity}
                                  size="5"
                                  readOnly
                                />

                                <i
                                  className="fa-solid fa-plus plus-btn"
                                  onClick={() =>
                                    setQuantity(
                                      (prevQuantity) => prevQuantity + 1
                                    )
                                  }
                                ></i>
                              </div>
                            </div>
                            <div className="col-item mx-2">
                              <button
                                onClick={() => handlecart()}
                                className="btn btn-lg main-btn"
                              >
                                <i className="icon-f-39"></i>{t("Add To Cart")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  );
};

export default QuickModal;
